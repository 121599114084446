import { css } from '@emotion/react'
import type { MutaBeneficiary, MutaBeneficiaryType } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import {
  BeneficiariesList,
  Button,
  DialogWithRows,
  MemberModal,
  PageTitle,
  RowWithLink,
  spacing,
  Text,
  useCrash,
  useDialogVisibility,
  useScreenType,
} from '@orus.eu/pharaoh'
import child from '@orus.eu/pharaoh/src/legacy/features/health-cover/emoji/child.png'
import kiss from '@orus.eu/pharaoh/src/legacy/features/health-cover/emoji/kiss.png'
import personWhiteHair from '@orus.eu/pharaoh/src/legacy/features/health-cover/emoji/person-white-hair.png'
import type { ReactElement } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { trpcReact } from '../../../client'
import { GlobalLoadingState } from '../../molecules/global-loading-state'
import type { FinalizeBeneficiaryMemberInfo } from '../subscription-v2/elements/muta-beneficiaries-element/finalize-beneficiaries-element'

export default function HealthInsuranceBeneficiariesPage(): ReactElement {
  const { data, isSuccess, isLoading } = trpcReact.muta.getMutaBeneficiariesInfos.useQuery()
  const [finalizeBeneficiaryMemberInfo, setFinalizeBeneficiaryMemberInfo] = useState<FinalizeBeneficiaryMemberInfo>()

  const crash = useCrash()
  const screenType = useScreenType()

  const mutaBeneficiaries = useMemo(() => {
    if (isLoading) return undefined
    if (!isSuccess) {
      crash(new TechnicalError('Unable to get beneficaries info'))
      return undefined
    }
    return data
  }, [crash, data, isLoading, isSuccess])

  const {
    show: showBeneficiaryModal,
    hide: hideBeneficiaryModal,
    visible: visibleBeneficiaryModal,
  } = useDialogVisibility(`see-member`)

  const {
    show: showMutaContactModal,
    hide: hideMutaContactModal,
    visible: visibleMutaContactModal,
  } = useDialogVisibility(`show-muta-contact`)

  const onBeneficiaryClick = useCallback(
    (beneficiary: MutaBeneficiary, index: number) => {
      const otherBeneficiaries = mutaBeneficiaries?.mutaBeneficiaries.filter((ben) => ben.id !== beneficiary.id) ?? []
      setFinalizeBeneficiaryMemberInfo({ beneficiaryToUpdate: beneficiary, otherBeneficiaries, index })
      showBeneficiaryModal()
    },
    [showBeneficiaryModal, mutaBeneficiaries],
  )

  if (isLoading) {
    return <GlobalLoadingState />
  }

  return (
    <>
      <PageTitle title="Vos bénéficiaires" fullWidth />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[70]};
        `}
      >
        {visibleBeneficiaryModal && finalizeBeneficiaryMemberInfo ? (
          <MemberModal
            emoji={avatarPerBeneficiaryType[finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.type]}
            title={namingPerBeneficiaryType[finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.type]}
            birthdateValue={{
              year: finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.birthDate?.year ?? 0,
              oneBasedMonth: finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.birthDate?.oneBasedMonth ?? 0,
              oneBasedDay: finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.birthDate?.oneBasedDay ?? 0,
            }}
            canDelete={false}
            canSubmit={false}
            firstNameLastNameAlreadyExists={false}
            cityValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.birthCity ?? ''}
            firstNameValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.firstName ?? ''}
            lastNameValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.lastName ?? ''}
            isDisabledAdult={false}
            onClose={hideBeneficiaryModal}
            postCodeValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.zipCode ?? ''}
            socialSecurityNumberValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.socialSecurityNumber ?? ''}
            countryValue={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.birthCountry}
            selectedRegime={[{ title: finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.regime ?? '' }]}
            regimes={[]}
            onBirthdateUpdate={() => {
              throw new TechnicalError("We can't update the muta birthdate on customer app")
            }}
            onChangeIsDisabledAdult={() => {
              throw new TechnicalError("We can't update the muta isDisabledAdult on customer app")
            }}
            onChangeLinkedFamilyMemberUpdate={() => {
              throw new TechnicalError("We can't update the muta linked family member on customer app")
            }}
            onFirstNameUpdate={() => {
              throw new TechnicalError("We can't update the muta firstname on customer app")
            }}
            onPostCodeUpdate={() => {
              throw new TechnicalError("We can't update the muta post code on customer app")
            }}
            isCustomerApp
            isPostQuote
            isChild={finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.type === 'CHILD'}
            linkedFamilyMember={
              finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.type === 'CHILD' &&
              finalizeBeneficiaryMemberInfo.beneficiaryToUpdate.isLinkedToPartner
                ? 'PARTNER'
                : 'YOU'
            }
          />
        ) : (
          <></>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${screenType === 'mobile' ? spacing[60] : spacing[70]};
          `}
        >
          {!mutaBeneficiaries ||
          !mutaBeneficiaries.mutaBeneficiaries ||
          !mutaBeneficiaries.mutaHasBeneficiaries ||
          mutaBeneficiaries.mutaBeneficiaries.length === 0 ? (
            <Text
              css={css`
                margin: ${spacing[50]} ${spacing[60]} ${spacing[50]} ${spacing[60]};
              `}
            >
              Vous n&apos;avez pas de bénéficiaire
            </Text>
          ) : (
            <BeneficiariesList
              beneficiaries={mutaBeneficiaries.mutaBeneficiaries}
              isCustomerApp
              onBeneficiaryClick={onBeneficiaryClick}
            />
          )}
          {visibleMutaContactModal ? (
            <DialogWithRows onClose={hideMutaContactModal} title="Nous contacter">
              <RowWithLink href='mailto:hello@orus.eu">hello@orus.eu' text="orus@cpms.fr" />
              <RowWithLink href="tel:0186479059" text="01 86 47 90 59" />
            </DialogWithRows>
          ) : (
            <></>
          )}
          <div
            css={css`
              margin-left: ${screenType === 'mobile' ? spacing[60] : 'auto'};
              margin-right: ${screenType === 'mobile' ? spacing[60] : ''};
              align-items: end;
            `}
          >
            <Button
              variant="secondary"
              fullWidth={screenType === 'mobile'}
              size={screenType === 'mobile' ? 'large' : 'medium'}
              onClick={showMutaContactModal}
            >
              Demande de modification
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const avatarPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: kiss,
  CHILD: child,
  OTHER: personWhiteHair,
}

const namingPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: 'Votre conjoint',
  CHILD: 'Votre enfant',
  OTHER: 'Une personne à charge',
}
